import { Controller } from "stimulus"

export default class extends Controller {

   
    connect() {

        console.log("THERAPY");

    
       let checkboxes = document.getElementsByClassName("single_checkbox_img");
       for(let i=0; i<checkboxes.length; i++){
           let checkbox = checkboxes[i];
           checkbox.addEventListener('click', (event) => {
              let checkbox = event.target;

              if(checkbox.classList.contains('unchecked')){
                    this.clearCheckboxes();
                    checkbox.classList.remove('unchecked');
                    checkbox.classList.add('checked');
                    checkbox.nextElementSibling.checked = true;

              }else{
               checkbox.classList.add('unchecked');
               checkbox.classList.remove('checked');
               checkbox.nextElementSibling.checked = false;

              }
            })
       }

       if(document.getElementById('exit')){
        document.getElementById('exit').addEventListener('click', (event) => {
            feedback_container.classList.add('inactive');
    
          })
       }

    
    

       

       let feedback_btns = document.getElementsByClassName("spielverlauf");
       console.log(feedback_btns);
       let feedback_container = document.getElementById('punkte_feedback_container');
       for(let i=0; i<feedback_btns.length; i++){
           let btn = feedback_btns[i];
           btn.addEventListener('click', (event) => {
            console.log("click");
              if(feedback_container.classList.contains('inactive')){
                feedback_container.classList.remove('inactive');
              }else{
                feedback_container.classList.add('inactive');
              }
            })
       }








       let multiple_checkboxes = document.getElementsByClassName("multiple_checkbox_img");
       for(let i=0; i<multiple_checkboxes.length; i++){
           let checkbox = multiple_checkboxes[i];
           checkbox.addEventListener('click', (event) => {
              let checkbox = event.target;

              if(checkbox.classList.contains('unchecked')){
                    checkbox.classList.remove('unchecked');
                    checkbox.classList.add('checked');
                    checkbox.nextElementSibling.checked = true;

              }else{
               checkbox.classList.add('unchecked');
               checkbox.classList.remove('checked');
               checkbox.nextElementSibling.checked = false;

              }
            })
       }

    }


   submitTherapy(evt){

      console.log(evt.target);

      let checked_ids = "";
      let checkboxes = document.getElementsByClassName("therapy_checkbox");
  
      for(let i=0; i<checkboxes.length; i++){
          let checkbox = checkboxes[i];
          if(checkbox.checked === true){
              let value = checkbox.id;
              checked_ids = checked_ids + value + ",";
          }
      }
      console.log(checked_ids);

      //this.checkboxTarget.value = checked_ids;
      document.getElementById('submitTherapy').value = checked_ids;
      let submit = document.getElementsByClassName("submit_button");
      //submit[0].click();


   }

   nextTherapy(evt){

      console.log(evt.target);

      let next_id = evt.target.getAttribute("data-next-therapy-step");

      document.getElementById('nextTherapy').value = next_id
      let submit = document.getElementsByClassName("advance_button");


   }




clearCheckboxes(){

    let checkboxes = document.getElementsByClassName("single_checkbox_img");

    for(let i=0; i<checkboxes.length; i++){
        let checkbox = checkboxes[i];
        checkbox.classList.remove('checked');
        checkbox.classList.add('unchecked');

        checkbox.nextElementSibling.checked = false;

      
    }
  
}
    
}

