import {Controller} from "stimulus"

export default class extends Controller {

    connect() {

        console.log("login");

        if(document.getElementById('login_button')){
            document.getElementById('login_button').addEventListener('click', (event) => {
                console.log(document.getElementById('login_container').getElementsByTagName("form"));
                document.getElementById('form_container').classList.toggle('collapsed');
        
              })
        }
    


    }


}

